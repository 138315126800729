function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { createStore } from 'jotai';
import { isClient } from "../../utils";
import { store } from "../../store";


import { globalMatomoAtom } from "./globalMatomoAtom";
import { ciMatomoActiveAtom } from "./ciMatomoActiveAtom";
import { relationshipTypesAtom } from "./relationshipTypes";
import { settingsAtom } from "./settingsAtom";
import { templatesAtom } from "./templatesAtom";
import { translationsAtom } from "./translationsAtom";
import { userAtom } from "./userAtom";
import { thesauriAtom } from "./thesauriAtom";

















const atomStore = createStore();

if (isClient && window.__atomStoreData__) {
  const { globalMatomo, locale, settings, thesauri, templates, user, ciMatomoActive } =
  window.__atomStoreData__;

  if (ciMatomoActive) atomStore.set(ciMatomoActiveAtom, ciMatomoActive);
  if (globalMatomo) atomStore.set(globalMatomoAtom, _objectSpread({}, globalMatomo));
  if (settings) atomStore.set(settingsAtom, settings);
  if (thesauri) atomStore.set(thesauriAtom, thesauri);
  if (templates) atomStore.set(templatesAtom, templates);
  atomStore.set(userAtom, user);
  atomStore.set(translationsAtom, { locale: locale || 'en' });

  //sync deprecated redux store
  atomStore.sub(settingsAtom, () => {
    const value = atomStore.get(settingsAtom);
    store === null || store === void 0 ? void 0 : store.dispatch({ type: 'settings/collection/SET', value });
  });
  atomStore.sub(templatesAtom, () => {
    const value = atomStore.get(templatesAtom);
    store === null || store === void 0 ? void 0 : store.dispatch({ type: 'templates/SET', value });
  });
  atomStore.sub(relationshipTypesAtom, () => {
    const value = atomStore.get(relationshipTypesAtom);
    store === null || store === void 0 ? void 0 : store.dispatch({ type: 'relationTypes/SET', value });
  });
  atomStore.sub(thesauriAtom, () => {
    const value = atomStore.get(thesauriAtom);
    store === null || store === void 0 ? void 0 : store.dispatch({ type: 'dictionaries/SET', value });
  });
}


export { atomStore };